//#region Constants

import { CustomGridColDef, GridCellWidgetType, GridColSortType } from "../components/RemoteTable/RemoteTable";
import { ValueType } from "../utils/formatter";
import { TableColorType } from "../utils/table";

const TABLE_NAME = 'dadosUltimos30Dias';

//#endregion Constants

export default class DadosUltimos30DiasModel {
  //#region Public Properties

  /* eslint-disable @typescript-eslint/no-inferrable-types*/
  public data: Date = new Date();
  public ean: bigint = BigInt(0);
  public codInterno: bigint = BigInt(0);
  public descArtigo: string = "";
  public store: bigint = BigInt(0);
  public storeName: string = "";
  public stock1DiasAntes: bigint = BigInt(0);
  public intransit1DiasAntes: bigint = BigInt(0);
  public expected1DiasAntes: bigint = BigInt(0);
  public presStock1DiasAntes: bigint = BigInt(0);
  public sellout1DiasAntes: bigint = BigInt(0);
  public fluxo: string = "";
  public sellout: bigint = BigInt(0);
  public stock: bigint = BigInt(0);
  public intransit: bigint = BigInt(0);
  public expected: bigint = BigInt(0);
  public presStock: bigint = BigInt(0);
  public valor?: number;
  public familia?: string;
  public subFamilia?: string;
  public grupoProduto?: string;
  public segmento?: string;
  public vendedor?: string;
  public rotura: number = 0;
  public rotura1DiasAntes: number = 0;
  public preRotura: number = 0;
  public preRotura1DiasAntes: number = 0;
  public stk: bigint = BigInt(0);
  public stk1DiasAntes: bigint = BigInt(0);
  public fornecimento: bigint = BigInt(0);
  public fornecimento1DiasAntes: bigint = BigInt(0);
  public ciclos: number = 0;
  public ciclos1DiasAntes: number = 0;
  public adequacao: string = "";
  public adequacao1DiasAntes?: string;
  public subinsignia: string = "";
  public rendimento?: number;
  public stock4DiasAntes?: bigint;
  public sellout4DiasAntes?: bigint;
  public intransit4DiasAntes?: bigint;
  public expected4DiasAntes?: bigint;
  public stk4DiasAntes?: bigint;
  public fornecimento4DiasAntes?: bigint;
  public ciclos4DiasAntes?: number;
  public adequacao4DiasAntes?: string;
  public stock5DiasAntes?: bigint;
  public sellout5DiasAntes?: bigint;
  public intransit5DiasAntes?: bigint;
  public expected5DiasAntes?: bigint;
  public stk5DiasAntes?: bigint;
  public fornecimento5DiasAntes?: bigint;
  public ciclos5DiasAntes?: number;
  public adequacao5DiasAntes?: string;
  public stock10DiasAntes?: bigint;
  public sellout10DiasAntes?: bigint;
  public intransit10DiasAntes?: bigint;
  public expected10DiasAntes?: bigint;
  public stk10DiasAntes?: bigint;
  public fornecimento10DiasAntes?: bigint;
  public ciclos10DiasAntes?: number;
  public adequacao10DiasAntes?: string;
  public msa10?: number;
  public msa10dp?: number;
  public msa104DiasAntes?: number;
  public msa10dp4DiasAntes?: number;
  public msa105DiasAntes?: number;
  public msa10dp5DiasAntes?: number;
  public msa1010DiasAntes?: number;
  public msa10dp10DiasAntes?: number;
  public msa20?: number;
  public msa20dp?: number;
  public msa204DiasAntes?: number;
  public msa20dp4DiasAntes?: number;
  public msa205DiasAntes?: number;
  public msa20dp5DiasAntes?: number;
  public msa2010DiasAntes?: number;
  public msa20dp10DiasAntes?: number;
  public balance: number = 0;
  public balance4DiasAntes: number = 0;
  public balance5DiasAntes: number = 0;
  public balance10DiasAntes: number = 0;
  public balanceRaw: number = 0;
  public balanceRawCount1: number = 0;
  public balanceRawCount2: number = 0;
  public balanceRawCount130Dias: number = 0;
  public balanceRawCount230Dias: number = 0;
  public balanceRawCount160Dias: number = 0;
  public balanceRawCount260Dias: number = 0;
  public balanceRawCount1120Dias: number = 0;
  public balanceRawCount2120Dias: number = 0;
  public selloutFds1DiasAntes: bigint = BigInt(0);
  public selloutSemana1DiasAntes?: bigint;
  public selloutFdsMedio30Dias: number = 0;
  public selloutSemanaMedio30Dias: number = 0;
  public selloutMedio30Dias: number = 0;
  public efeitoFds30Dias?: number;
  public selloutFdsMedio60Dias: number = 0;
  public selloutSemanaMedio60Dias: number = 0;
  public selloutMedio60Dias: number = 0;
  public efeitoFds60Dias?: number;
  public selloutFdsMedio120Dias: number = 0;
  public selloutSemanaMedio120Dias: number = 0;
  public selloutMedio120Dias: number = 0;
  public efeitoFds120Dias?: number;
  public newSupply: number = 0;
  public stockBorderline1DiasAntes: number = 0;
  public linearIncompleto1DiasAntes: number = 0;
  public semVendas1DiasAntes: number = 0;
  public semVendas: number = 0;
  public semVendas1DiasAntesNr: number = 0;
  public roturaFds1DiasAntes?: number;
  public roturaSemana1DiasAntes: number = 0;
  public groups?: number;
  public diasParaEntrega?: number;
  public unidadesEncomendadas?: bigint;
  public percentagemVolatilidade30Dias: number = 0;
  public percentagemRoturas30Dias: number = 0;
  public percentagemSupply30Dias: number = 0;
  public percentagemDiasStockBorderline30Dias: number = 0;
  public percentagemDiasLinearIncompleto30Dias: number = 0;
  public percentagemDiasSemVendas30Dias: number = 0;
  public vendasPerdidasEm30Dias: number = 0;
  public mediaDiasParaEntrega30Dias?: number;
  public selloutUltimos30Dias: bigint = BigInt(0);
  public percentagemDiasSemVendasNr30Dias: number = 0;
  public percentagemVolatilidade60Dias: number = 0;
  public percentagemRoturas60Dias: number = 0;
  public percentagemSupply60Dias: number = 0;
  public percentagemDiasStockBorderline60Dias: number = 0;
  public percentagemDiasLinearIncompleto60Dias: number = 0;
  public percentagemDiasSemVendas60Dias: number = 0;
  public vendasPerdidasEm60Dias: number = 0;
  public mediaDiasParaEntrega60Dias?: number;
  public selloutUltimos60Dias: bigint = BigInt(0);
  public percentagemDiasSemVendasNr60Dias: number = 0;
  public percentagemVolatilidade120Dias: number = 0;
  public percentagemRoturas120Dias: number = 0;
  public percentagemSupply120Dias: number = 0;
  public percentagemDiasStockBorderline120Dias: number = 0;
  public percentagemDiasLinearIncompleto120Dias: number = 0;
  public percentagemDiasSemVendas120Dias: number = 0;
  public vendasPerdidasEm120Dias: number = 0;
  public mediaDiasParaEntrega120Dias?: number;
  public selloutUltimos120Dias: bigint = BigInt(0);
  public percentagemDiasSemVendasNr120Dias: number = 0;
  public interSupply?: bigint;
  public interSupplyMed30Dias?: number;
  public interSupplyMed60Dias?: number;
  public interSupplyMed120Dias?: number;
  public contagemSemana3Dias: bigint = BigInt(0);
  public contagemFds3Dias: bigint = BigInt(0);
  public contagemSemana5Dias: bigint = BigInt(0);
  public contagemFds5Dias: bigint = BigInt(0);
  public selloutPrevisto3Dias: number = 0;
  public selloutPrevisto5Dias: number = 0;
  public balanceSmart3Dias: number = 0;
  public balanceSmart3DiasAntes?: number;
  public balanceSmart5Dias: number = 0;
  public balanceSmart5DiasAntes?: number;
  public diasParaRoturaStock?: number;
  public diasParaRoturaStock5DiasAntes?: number;
  public diasDuracaoLinear?: number;
  public diasDuracaoLinear5DiasAntes?: number;
  public taxaConversaoSpace?: number;
  public taxaConversaoSpace30Dias: number = 0;
  public taxaConversaoSpace60Dias: number = 0;
  public taxaConversaoSpace120Dias: number = 0;
  public desempenhoDeVendas60Dias: string = "";
  public volatilidadeSellout: string = "";
  public sensibilidadeRotura: string = "";
  public orderSensibilidadeRotura: number = 0;
  public psClassificacao60Dias: string = "";
  public risco: number = 0;
  public riscoRotura: string = "";
  public conclusao1: string = "";
  public conclusao2: string = "";
  public conclusao3: string = "";
  public dataRight?: Date;
  public storeRight: bigint = BigInt(0);
  public eanRight: bigint = BigInt(0);
  public ano: number = 0;
  public mes: number = 0;
  public mesAnterior: number = 0;
  public mediaSelloutMensal: number = 0;
  public alertaBrands?: string;
  public previsaoBn?: string;
  public roturaExtenso?: string;
  public vendasPerdidas?: number;
  public vendasPerdidasEvitaveis?: number;
  public vendasPerdidasConsecutivas?: number;
  public roturasConsecutivas?: number;
  public semVendasConsecutivas?: number;
  public valorPerdido?: number;
  public semana: number = 0;

  //#endregion Public Properties

  //#region Static Methods

  public static getColumnsConfig = (): { [key in keyof DadosUltimos30DiasModel]?: CustomGridColDef<DadosUltimos30DiasModel>} => ({
    data: {
      formatType: ValueType.DATE
    },
    vendedor: {
      formatType: ValueType.STRING
    },
    storeName: {
      formatType: ValueType.STRING
    },
    descArtigo: {
      formatType: ValueType.STRING
    },
    rotura: {
      formatType: ValueType.NUMBER,
      defaultSorted: GridColSortType.DESC,
      widget: GridCellWidgetType.PROGRESS,
      cellConditionalFormat: {
        condition: (value: unknown) => value as number > 0,
        color: TableColorType.ORANGE
      }
    },
    sellout: {
      formatType: ValueType.NUMBER
    },
    stock: {
      formatType: ValueType.NUMBER
    },
    fornecimento: {
      formatType: ValueType.NUMBER
    },
    selloutFdsMedio30Dias: {
      formatType: ValueType.NUMBER
    },
    selloutSemanaMedio30Dias: {
      formatType: ValueType.NUMBER
    },
    vendasPerdidas: {
      formatType: ValueType.NUMBER
    },
    semVendas: {
      formatType: ValueType.NUMBER
    },
    semVendasConsecutivas: {
      formatType: ValueType.NUMBER
    },
    codInterno: {
      formatType: ValueType.STRING
    },
    selloutUltimos30Dias: {
      formatType: ValueType.NUMBER
    },
    mediaDiasParaEntrega60Dias: {
      formatType: ValueType.NUMBER
    },
    vendasPerdidasConsecutivas: {
      formatType: ValueType.NUMBER
    }
  });

  public static getCustomFilters = (): { [key: string]: string} => ({ table: TABLE_NAME});

  public static getTableName = () => TABLE_NAME;

  //#endregion Static Methods
}
