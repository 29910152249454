import React, {
  InputHTMLAttributes,
  KeyboardEvent,
  TextareaHTMLAttributes,
  useCallback,
  useEffect
} from 'react'

import MagnifyingGlass from '../../assets/icons/magnifyingGlass.svg'
import useDebounce from '../../hooks/useDebounce'

import styles from './TextInput.module.scss'


type TextInputProps = {
  title?: string
  value: string
  onChange: (v: string) => void
  multiline?: boolean
  noBorders?: boolean
  disabled?: boolean
  endSymbol?: string
  maxLength?: number
  onEnter?: () => void
  pill?: boolean
  search?: boolean
  debounceHandler?: (v: string) => void
  customWidth?: string
} & (Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>)
  & (Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>)


export const TextInput = ({
  title,
  placeholder,
  value,
  onChange,
  multiline,
  noBorders,
  disabled,
  endSymbol,
  maxLength,
  onEnter,
  pill,
  search,
  customWidth,
  debounceHandler,
  ...rest
}: TextInputProps) => {
  const debouncedValue = useDebounce(value, 500)


  // Handle the input change
  const handleOnChange = (v: string) => {
    if (onEnter && v[v.length - 1] === '\n') {
      onEnter()
      return
    }

    let newValue = v.trimStart()

    if (endSymbol) {
      if (newValue.includes(endSymbol)) {
        newValue = newValue.replace(endSymbol, '')
      } else {
        // Remove last character if v doesn't contain the end symbol because the user
        // tried to erase a char and removed the symbol instead, so we remove here
        newValue = newValue.slice(0, -1)
      }
    }

    if (maxLength && newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength)
    }

    onChange(newValue)
  }


  // Handle onEnter event
  const handleOnEnter = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (onEnter && e.key === 'Enter') {
      onEnter()
    }
  }, [onEnter])


  // Debounce handler for when the user is typing
  useEffect(() => {
    if (!debounceHandler || disabled) return
    debounceHandler(debouncedValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]) // We only want to call the debounce handler when the debounced value changes


  return (
    <div
      className={`
        ${styles.textInput}
        ${noBorders && styles.noBorders}
        ${disabled && styles.disabled}
        ${pill && styles.pill}
      `}
      style={{ width: customWidth }}
    >
      {title && (
        <h4 className={styles.title}>
          {title}
        </h4>
      )}
      <div className={styles.flex}>
        {search && (
          <img
            className={styles.searchIcon}
            src={MagnifyingGlass}
            alt='search_icon'
            draggable={false}
          />
        )}
        {multiline ? (
          <textarea
            className={`${styles.input} ${disabled && styles.disabled}`}
            value={disabled ? '' : `${value}${endSymbol ?? ''}`}
            onChange={e => handleOnChange(e.target.value)}
            placeholder={placeholder ?? ''}
            rows={5}
            {...rest}
          />
        ) : (
          <input
            className={`${styles.input} ${disabled && styles.disabled}`}
            value={disabled ? '' : `${value}${endSymbol ?? ''}`}
            onChange={e => handleOnChange(e.target.value)}
            placeholder={placeholder ?? ''}
            onKeyDown={handleOnEnter}
            {...rest}
          />
        )}
      </div>
    </div>
  )
}
