import { PermissionType } from "../api/types"

type ID = string | number

/////////////////////////////////////////////////////////////////////
//                         !! WARNING !!                           //
//     When creating a new path, make sure the index is unique     //
//     SideBar.tsx uses the index to determine the active tab      //
//                                                                 //
//     When adding a new path to 'getPaths', consider adding       //
//        its index to 'sideBarItemsPaths', if necessary           //
//                         !! WARNING !!                           //
/////////////////////////////////////////////////////////////////////

/* eslint-disable i18next/no-literal-string */
export const getPaths = {
  home: '/',
  managers: {
    _base: 'managers',
    signIn: '/managers/sign_in'
  },
  brands: {
    _base: 'brands',
    no_permissions: {
      _base: 'no_permissions',
      index: '/brands/no_permissions'
    },
    missions: {
      _base: 'missions',
      index: '/brands/missions',
      ongoing: '/brands/missions/ongoing',
      before_start: '/brands/missions/before_start',
      completed: '/brands/missions/completed',
      in_the_making: '/brands/missions/in_the_making'
    },
    mission: {
      _base: 'mission',
      index: '/brands/mission',
      details: (id: ID) => `/brands/mission/${id}/details`,
      stores_summary: (id: ID) => `/brands/mission/${id}/stores_summary`,
      stats: (id: ID) => `/brands/mission/${id}/stats`,
      answers: (id: ID) => `/brands/mission/${id}/answers`,
      ninjas_assign: (id: ID) => `/brands/mission/${id}/ninjas_assign`
    },
    wizard: {
      _base: 'wizard',
      index: '/brands/wizard',
      step_1: (id: ID) => `/brands/wizard/${id}/step_1`,
      step_2: (id: ID) => `/brands/wizard/${id}/step_2`,
      step_3: (id: ID) => `/brands/wizard/${id}/step_3`,
      step_4: (id: ID) => `/brands/wizard/${id}/step_4`
    },
    dashboard: {
      _base: 'dashboard',
      index: '/brands/dashboard',
      tab: (tab: ID) => `/brands/dashboard/${tab}`
    }
  }
} as const


export const sideBarItemsPaths = {
  missions: [
    getPaths.brands.missions.index,
    getPaths.brands.mission.index,
    getPaths.brands.wizard.index
  ],
  dashboard: [
    getPaths.brands.dashboard.index
  ]
} as const

export type sideBarItemsIds = keyof typeof sideBarItemsPaths


type RedirectPermissionsType = Record<PermissionType, {
  path: string,
  redirects: PermissionType[]
}>

export const redirectPermissions: RedirectPermissionsType = {
  'missions_viewer': {
    path: getPaths.brands.missions.ongoing,
    redirects: [
      'dashboard_viewer'
    ],
  },
  'mission_creator': {
    path: getPaths.brands.missions.ongoing,
    redirects: [
      'missions_viewer',
      'dashboard_viewer'
    ],
  },
  'answers_validator': {
    path: getPaths.brands.missions.ongoing,
    redirects: [
      'missions_viewer',
      'dashboard_viewer'
    ]
  },
  'dashboard_viewer': {
    path: getPaths.brands.dashboard._base,
    redirects: [
      'missions_viewer'
    ]
  }
}
