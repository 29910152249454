import { CustomGridColDef, GridColSortType } from "../../components/RemoteTable/RemoteTable";
import { ValueType } from "../../utils/formatter";

export default class BaseAvisoModel {

  //#region Public Properties

  /* eslint-disable @typescript-eslint/no-inferrable-types*/
  public data: Date = new Date();
  public vendedor: string = '';
  public storeName: string = '';
  public descArtigo: string = '';
  public familia: string = '';
  public subFamilia: string = '';
  public stock: number = 0;
  public sellout: number = 0;
  public roturasConsecutivas: number = 0;
  public fluxo: string = '';
  public roturaExtenso: string = '';
  public alertaBrands: string = '';
  public valor?: number;
  public fornecimento: number = 0;
  public percentagemDiasSemVendas60: number = 0;
  public percentagemRoturas60: number = 0;

  //#endregion Public Properties

  //#region Static Methods

  private static getBaseColumnsConfig = (): { [key in keyof BaseAvisoModel]?: CustomGridColDef<BaseAvisoModel>} => 
    ({
      data: {
        formatType: ValueType.DATE
      },
      vendedor: {
        formatType: ValueType.STRING,
        defaultSorted: GridColSortType.DESC
      },
      storeName: {
        formatType: ValueType.STRING
      },
      descArtigo: {
        formatType: ValueType.STRING
      },
      subFamilia: {
        formatType: ValueType.STRING
      },
      stock: {
        formatType: ValueType.NUMBER
      },
      sellout: {
        formatType: ValueType.NUMBER
      },
      roturasConsecutivas: {
        formatType: ValueType.NUMBER,
        // eslint-disable-next-line i18next/no-literal-string
        headerResourceName: 'DiasRotura'
      },
      fornecimento: {
        formatType: ValueType.NUMBER
      },
      percentagemDiasSemVendas60: {
        formatType: ValueType.PERCENTAGE
      },
      percentagemRoturas60: {
        formatType: ValueType.PERCENTAGE
      },
      fluxo: {
        formatType: ValueType.STRING
      }
    });

  public static getColumnsDefinition = (columnNames: (keyof BaseAvisoModel)[], defaultSorted: boolean = true): object => {
    const baseColumnsConfig = this.getBaseColumnsConfig();

    return columnNames?.reduce((result: object, columnName: keyof BaseAvisoModel) => {
      const columnConfig = baseColumnsConfig[columnName];
      if(!defaultSorted) {
        delete columnConfig?.defaultSorted;
      }
      return {...result, [columnName]: columnConfig};
    }, {}) ?? baseColumnsConfig;
  };

  //#endregion Static Methods

}
