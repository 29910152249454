import { useCallback } from 'react'
import { useSearchParams as useSearchParamsRRD } from 'react-router-dom'


export type searchParamsType = {
  [key: string]: string | string[]
}


function useSearchParams() {
  const [searchParams, setSearchParams] = useSearchParamsRRD()


  const getSearchParams = useCallback(() => {
    return new URLSearchParams(searchParams.toString())
  }, [searchParams])


  const updateSearchParams = useCallback((params: searchParamsType) => {
    const newSearchParams = getSearchParams()
    Object.keys(params).forEach(key => {
      const value = params[key]
      if (Array.isArray(value)) {
        newSearchParams.delete(key)
        value.forEach(v => newSearchParams.append(key, v))
      } else {
        newSearchParams.set(key, value)
      }
    })
    setSearchParams(newSearchParams)
  }, [getSearchParams, setSearchParams])


  const removeSearchParams = useCallback((keys: string[] | string) => {
    const newSearchParams = getSearchParams()
    if (typeof keys === 'string') keys = [keys]
    keys.forEach(key => newSearchParams.delete(key))
    setSearchParams(newSearchParams)
  }, [getSearchParams, setSearchParams])


  const clearSearchParams = useCallback((guaranteeRefetch?: boolean) => {
    // When the URLSearchParam is already clean and the clearSearchParams is called
    // the data is not refetched (ex: when using usePagyRequest), since the search params don't change.
    // This guarantees that if the clearSearchParams is called with guaranteeRefetch,
    // even if the URLSearchParam does not change, the data is refetched to keep it updated
    // Note: The usePagyRequest hook deals with -1 not being a valid page and calls the api with page 1
    if (guaranteeRefetch) setSearchParams(new URLSearchParams({ page: '-1' }))
    else setSearchParams(new URLSearchParams())
  }, [setSearchParams])


  const searchParamsEmpty = useCallback(() => {
    return searchParams.size === 0
  }, [searchParams])


  return {
    // So that the user can access the original searchParams from the same place
    searchParams,
    setSearchParams,
    // Custom functions
    getSearchParams,
    updateSearchParams,
    removeSearchParams,
    clearSearchParams,
    searchParamsEmpty
  }
}


export default useSearchParams
