import React, { ReactNode, useCallback, useMemo, useState } from 'react'

import CheckMark from '../../assets/icons/checkMark.svg'
import { Checkbox } from '../../elements/Checkbox'
import { Loading } from '../shared/Loading'

import styles from './Table.module.scss'


type ColumnType<T> = {
  id: string
  label: string
  flexSpace?: number
  getValue?: (item: T) => string
}

type TableProps<T> = {
  loading: boolean
  columns: ColumnType<T>[]
  items: T[]
  selectedItems: T[]
  getItemId: (item: T) => number
  isItemDisabled?: (item: T) => boolean
  handleSelectItem: (item: T) => void
  handleSelectAllItems?: (select: boolean) => void
  pagyIndicator: ReactNode
  labels?: ReactNode
}


export const Table = <T,>({
  loading,
  columns,
  items,
  selectedItems,
  getItemId,
  isItemDisabled,
  handleSelectItem,
  handleSelectAllItems,
  pagyIndicator,
  labels
}: TableProps<T>) => {
  const [hoveredItemId, setHoveredItemId] = useState<number | null>(null)
  const [mouseDown, setMouseDown] = useState<boolean>(false)

  const itemsIds = useMemo(() => items.map(i => getItemId(i)), [items, getItemId])
  const selectedItemsIds = useMemo(() => selectedItems.map(i => getItemId(i)), [selectedItems, getItemId])


  const allItemsSelected = useMemo(() => {
    if (itemsIds.length === 0) return false

    return (itemsIds.every(id => selectedItemsIds.includes(id)))
  }, [itemsIds, selectedItemsIds])


  // Logic to handle the hover effect and actions on click
  const handleOnEnterRow = useCallback((itemId: number) => {
    setHoveredItemId(itemId)
  }, [])

  const handleOnLeaveRow = useCallback((itemId: number) => {
    if (hoveredItemId === itemId) setHoveredItemId(null)
  }, [hoveredItemId])


  return ( 
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.table}>
          <div className={styles.tableTop}>
            {handleSelectAllItems ? (
              <Checkbox
                checked={allItemsSelected}
                handler={() => handleSelectAllItems(!allItemsSelected)}
              />
            ) : (
              <div className={styles.spacer} />
            )}
            {columns.map((column, idx) => (
              <span
                className={styles.header}
                key={`tableHeader_${column.id}_Index${idx}_label${column.label}`}
                style={{ flex: column.flexSpace || 2 }}
              >
                {column.label}
              </span>
            ))}
          </div>
          <div className={styles.tableContent}>
            {items.map((item, idx) => (
              <div
                onMouseEnter={() => handleOnEnterRow(getItemId(item))}
                onMouseLeave={() => handleOnLeaveRow(getItemId(item))}
                onMouseDown={() => setMouseDown(true)}
                onMouseUp={() => setMouseDown(false)}
                onClick={() => handleSelectItem(item)}
                className={`
                  ${styles.row}
                  ${mouseDown && styles.mouseDown}
                  ${hoveredItemId === getItemId(item) && styles.hovered}
                  ${(isItemDisabled && isItemDisabled(item)) && styles.disabled}
                  ${selectedItemsIds.includes(getItemId(item)) && styles.checked}
                  ${selectedItemsIds.includes(getItemId(item)) && styles.selected}
                `}
                key={`tableLine${idx}`}
              >
                <div className={styles.checkbox}>
                  <img
                    src={CheckMark}
                    alt='V'
                    className={`${styles.checkMark}`}
                    draggable={false}
                  />
                </div>
                {columns.map(column => (
                  <div
                    className={styles.cell}
                    style={{ flex: column.flexSpace || 2 }}
                    key={`tableLine${idx}_Column${column.id}`}
                  >
                    <span>
                      {column.getValue ? column.getValue(item) : item[column.id]}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className={styles.tableBottom}>
            {pagyIndicator}
            {labels}
          </div>
        </div>
      )}
    </>
  )
}
