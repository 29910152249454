import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { getStoreCities, getStoreFilters } from '../../api'
import { StoreChainTypeAPI, StoreGroupTypeAPI, StoreTypeTypeAPI } from '../../api/types'
import cross from '../../assets/icons/cross.svg'
import { AssignConsts, EmptyFiltersToFetchStores, StoreStatusTypeAPI } from '../../Constants'
import { Button } from '../../elements/Button'
import { MultiDropdown } from '../../elements/MultiDropdown'
import { MultiDropdownFetch } from '../../elements/MultiDropdownFetch'
import { TextInput } from '../../elements/TextInput'
import useSearchParams from '../../hooks/useSearchParams'

import styles from './StoresFilters.module.scss'

const { ASSIGN_STATUS_FILTERS } = AssignConsts


export type FiltersToFetchStoresType = {
  storeTypes: StoreTypeTypeAPI[]
  storeChains: StoreChainTypeAPI[]
  storeGroups: StoreGroupTypeAPI[]
  status?: StoreStatusTypeAPI[]
  cities: string[]
  storeNameOrCode: string
  missionId?: number
}

type StoresFiltersProps = {
  pill?: boolean
  loading: boolean
  setLoading: (loading: boolean) => void
} & ({
  // This is Step2 of the Wizard
  // areSearchParams: false
  handleSearchStores: () => void
  filtersToFetchStores: FiltersToFetchStoresType
  setFiltersToFetchStores: React.Dispatch<React.SetStateAction<FiltersToFetchStoresType>>
} | {
  // This is the assign ninjas in mission page => searchParams!
  // areSearchParams: true
  handleSearchStores?: undefined
  filtersToFetchStores?: undefined
  setFiltersToFetchStores?: undefined
})


export const StoresFilters = ({
  pill,
  loading,
  setLoading,
  filtersToFetchStores,
  setFiltersToFetchStores,
  handleSearchStores,
}: StoresFiltersProps) => {
  const i18n = useTranslation()
  const { searchParams, updateSearchParams, clearSearchParams } = useSearchParams()

  const [storeFilterText, setStoreFilterText] = useState(filtersToFetchStores ? filtersToFetchStores.storeNameOrCode : searchParams.get('storeNameOrCode') || '')


  // Static data from API
  const [storeTypeFilterOptions, setStoreTypeFilterOptions] = useState<StoreTypeTypeAPI[]>([])
  const [storeChainFilterOptions, setStoreChainFilterOptions] = useState<StoreChainTypeAPI[]>([])
  const [storeGroupFilterOptions, setStoreGroupFilterOptions] = useState<StoreGroupTypeAPI[]>([])


  // If we change the storeNameOrCode throught searchParams, we should change it here also
  useEffect(() => {
    if (filtersToFetchStores) return

    setStoreFilterText(searchParams.get('storeNameOrCode') || '')
  }, [searchParams, filtersToFetchStores])


  // Selected filters
  const selectedStoreTypes = useMemo(() => {
    if (filtersToFetchStores) return filtersToFetchStores.storeTypes
    const selectedIds = searchParams.getAll('storeTypes')?.map(Number) || []
    return storeTypeFilterOptions.filter(store => selectedIds.includes(store.id))
  }, [filtersToFetchStores, searchParams, storeTypeFilterOptions])

  const selectedStoreChains = useMemo(() => {
    if (filtersToFetchStores) return filtersToFetchStores.storeChains
    const selectedIds = searchParams.getAll('storeChains')?.map(Number) || []
    return storeChainFilterOptions.filter(store => selectedIds.includes(store.id))
  }, [filtersToFetchStores, searchParams, storeChainFilterOptions])

  const selectedStoreGroups = useMemo(() => {
    if (filtersToFetchStores) return filtersToFetchStores.storeGroups
    const selectedIds = searchParams.getAll('storeGroups')?.map(Number) || []
    return storeGroupFilterOptions.filter(store => selectedIds.includes(store.id))
  }, [filtersToFetchStores, searchParams, storeGroupFilterOptions])

  const selectedStoreStatuses = useMemo(() => {
    if (filtersToFetchStores) return filtersToFetchStores.status
    const selectedIds = searchParams.getAll('status') || []
    return Object.values(ASSIGN_STATUS_FILTERS).filter(status => selectedIds.includes(status.id))
  }, [filtersToFetchStores, searchParams])

  const selectedCities = useMemo(() => {
    if (filtersToFetchStores) return filtersToFetchStores.cities
    return searchParams.getAll('cities') || []
  }, [filtersToFetchStores, searchParams])


  // Get filters from API
  useEffect(() => {
    getStoreFilters().then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:CoudntGetStoresFilters'))
        return
      }

      setStoreTypeFilterOptions(response.data.types)
      setStoreChainFilterOptions(response.data.chains)
      setStoreGroupFilterOptions(response.data.groups)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading])


  // Handle the update of the filters
  const handleUpdateFilter = useCallback((filterName, filterValue, getFilterId) => {
    if (setFiltersToFetchStores) {
      setFiltersToFetchStores(filters => ({
        ...filters,
        [filterName]: filterValue
      }))
    } else {
      if (filterName === 'storeNameOrCode') {
        updateSearchParams({ [filterName]: filterValue })
      } else {
        updateSearchParams({ [filterName]: filterValue.map(v => getFilterId(v)) })
      }
    }
  }, [setFiltersToFetchStores, updateSearchParams])


  return (
    <div className={styles.container}>
      <div className={`${styles.filters} ${pill ? styles.table : styles.wizard}`}>
        <TextInput
          value={storeFilterText}
          title={i18n.t('MissionWizard:StoreNameOrCode')}
          placeholder={i18n.t('MissionWizard:WriteHereStoreNameOrCode')}
          disabled={loading}
          onChange={v => setStoreFilterText(v)}
          // eslint-disable-next-line i18next/no-literal-string
          debounceHandler={v => handleUpdateFilter('storeNameOrCode', v, v => v)}
        />
        <MultiDropdown<StoreTypeTypeAPI>
          options={storeTypeFilterOptions}
          selected={selectedStoreTypes}
          getOptionLabel={opt => opt.name}
          getOptionId={opt => opt.id.toString()}
          filterBy={opt => opt.name}
          title={i18n.t('Common:Type')}
          height='20rem'
          disabled={loading}
          onChange={v => handleUpdateFilter('storeTypes', v, v => v.id)}
        />
        <MultiDropdown<StoreChainTypeAPI>
          options={storeChainFilterOptions}
          selected={selectedStoreChains}
          getOptionLabel={opt => opt.name}
          getOptionId={opt => opt.id.toString()}
          filterBy={opt => opt.name}
          title={i18n.t('Common:Chain')}
          height='20rem'
          disabled={loading}
          onChange={v => handleUpdateFilter('storeChains', v, v => v.id)}
        />
        <MultiDropdownFetch<string>
          selected={selectedCities}
          getOptionLabel={opt => opt}
          getOptionId={opt => opt}
          fetcher={getStoreCities}
          dataKey='cities'
          title={i18n.t('Common:City')}
          height='20rem'
          disabled={loading}
          onChange={v => handleUpdateFilter('cities', v, v => v)}
        />
        <MultiDropdown<StoreGroupTypeAPI>
          options={storeGroupFilterOptions}
          selected={selectedStoreGroups}
          getOptionLabel={opt => opt.name}
          getOptionId={opt => opt.id.toString()}
          filterBy={opt => opt.name}
          title={i18n.t('Common:Group')}
          height='20rem'
          disabled={loading}
          onChange={v => handleUpdateFilter('storeGroups', v, v => v.id)}
        />
        {!setFiltersToFetchStores &&
          <MultiDropdown<StoreStatusTypeAPI>
            options={Object.values(ASSIGN_STATUS_FILTERS)}
            selected={selectedStoreStatuses || []}
            getOptionLabel={opt => opt.label}
            getOptionId={opt => opt.id}
            filterBy={opt => opt.label}
            title={i18n.t('Common:Statuses')}
            height='20rem'
            disabled={loading}
            onChange={v => handleUpdateFilter('status', v, v => v.id)}
          />
        }
      </div>
      {!setFiltersToFetchStores ? (
        <Button
          icon={cross}
          handler={clearSearchParams}
          circle
        />
      ) : handleSearchStores && (
        <>
          <Button
            title={i18n.t('Common:Search')}
            handler={handleSearchStores}
            disabled={loading}
            fullHeight
          />
          <Button
            title={i18n.t('Common:Clear')}
            handler={() => setFiltersToFetchStores(EmptyFiltersToFetchStores)}
            disabled={loading}
            variant='secondary'
            fullHeight
          />
        </>
      )}
    </div>
  )
}
