import { GetStoresFiltersTypeAPI } from '../api/types'

/* eslint-disable i18next/no-literal-string */
export const storesFiltersSearchParams = (data: Partial<GetStoresFiltersTypeAPI>) => {
    let filters = ''
    if (data.page) filters += `&page=${data.page}`
    if (data.placeTypeIds) filters += `&place_type_ids=${data.placeTypeIds.join(',')}`
    if (data.storeChainIds) filters += `&store_chain_ids=${data.storeChainIds.join(',')}`
    if (data.storeGroupIds) filters += `&group_ids=${data.storeGroupIds.join(',')}`
    if (data.cities) filters += `&city=${data.cities.join(',')}`
    if (data.storeNameOrCode) filters += `&name_or_code=${data.storeNameOrCode}`
    if (data.status) filters += `&status=${data.status}`

    return filters.replace('&', '?')
}
/* eslint-enable i18next/no-literal-string */

