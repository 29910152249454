import { CustomGridColDef } from "../components/RemoteTable/RemoteTable";
import { ValueType } from "../utils/formatter";
import { TableColorType } from "../utils/table";

import BaseAvisoModel from "./base/BaseAvisoModel";

//#region Enums

enum AlertType {
  SAFE_STOCK = 'Stock Assegurado',
  MEDIUM_RISK = 'Médio Risco',
  HIGH_RISK = 'Elevado Risco'
}

//#endregion Enums

//#region Constants

const TABLE_NAME = 'avisoRoturaSemanaSeguinte';

//#endregion Constants

export default class AvisoRoturaSemanaSeguinteModel extends BaseAvisoModel {

  //#region Public Properties
  
  /* eslint-disable @typescript-eslint/no-inferrable-types*/
  public previsaoBn: string = '';
  public grupoProduto: string = '';
  public segmento: string = '';
  public estado: string = '';
  public ean: number = 0;
  public store: number = 0;
  public balanceSmart: number = 0;
  public selloutPrevisto: number = 0;
  public intransit: number = 0;
  public expected: number = 0;

  //#endregion Public Properties

  //#region Static Methods

  public static getColumnsConfig = (): { [key in keyof AvisoRoturaSemanaSeguinteModel]?: CustomGridColDef<AvisoRoturaSemanaSeguinteModel>} => ({
    // eslint-disable-next-line i18next/no-literal-string
    ...BaseAvisoModel.getColumnsDefinition(['data','vendedor','storeName','subFamilia','descArtigo','sellout','stock','roturasConsecutivas']),
    estado: {
      formatType: ValueType.STRING
    },
    intransit: {
      formatType: ValueType.NUMBER
    },
    expected: {
      formatType: ValueType.NUMBER
    },
    selloutPrevisto: {
      formatType: ValueType.NUMBER
    },
    alertaBrands: {
      formatType: ValueType.STRING,
      cellConditionalFormat: [
        {
          color: TableColorType.GREEN,
          condition: (value: unknown) => value === AlertType.SAFE_STOCK
        },
        {
          color: TableColorType.YELLOW,
          condition: (value: unknown) => value === AlertType.MEDIUM_RISK
        },
        {
          color: TableColorType.RED,
          condition: (value: unknown) => value === AlertType.HIGH_RISK
        }
      ]
    },
    store: {
      formatType: ValueType.STRING
    },
    ean: {
      formatType: ValueType.STRING
    }
  });

  public static getCustomFilters = (): { [key: string]: string} => ({ table: TABLE_NAME});

  //#endregion Static Methods

}
