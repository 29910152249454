import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { assignMissionStoreToNinja, getNinjas, GetNinjasParamsTypeAPI, unassignMissionStoreFromNinja } from '../../api'
import { AssignMissionStoresToNinjaTypeAPI, MissionStoreDataTypeAPI, NinjaDataTypeAPI } from '../../api/types'
import { Table } from '../../components/Table'
import { AssignConsts, UnassignNinjaTemplate } from '../../Constants'
import { Button } from '../../elements/Button'
import { Checkbox } from '../../elements/Checkbox'
import { TextInput } from '../../elements/TextInput'
import useDebounce from '../../hooks/useDebounce'
import usePagyRequest from '../../hooks/usePagyRequest'
import { ModalWithButton } from '../ModalWithButton'

import styles from './AssignNinjasModalButton.module.scss'

const { NINJA_TABLE_COLUMNS } = AssignConsts


type AssignNinjasModalButtonProps = {
  selectedMissionStores: MissionStoreDataTypeAPI[]
  handleCleanPageAfterAssign: () => void
}


export const AssignNinjasModalButton = ({
  selectedMissionStores,
  handleCleanPageAfterAssign
}: AssignNinjasModalButtonProps) => {
  const i18n = useTranslation()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [step, setStep] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const [filterText, setFilterText] = useState<string>('')
  const [ninjas, setNinjas] = useState<NinjaDataTypeAPI[]>([])
  const [selectedNinja, setSelectedNinja] = useState<NinjaDataTypeAPI | null>(null)
  const [loadingAssign, setLoadingAssign] = useState<boolean>(false)

  const debounceFilter = useDebounce(filterText, 500)


  // Deals with fetching the ninjas and paginating them
  const { loading, pagyIndicator } = usePagyRequest<GetNinjasParamsTypeAPI, NinjaDataTypeAPI>({
    requestFunction: getNinjas,
    requestParams: { filter: debounceFilter, count: 7 },
    setData: setNinjas,
    errorText: i18n.t('Stores:ErrorFetchingStores'),
    pageIndicatorVariant: 'table',
    noSearchParamPage: page,
    setNoSearchParamPage: setPage
  })


  // Check if the page is loading
  const masterLoading = useMemo(() => loading || loadingAssign, [loading, loadingAssign])


  // Handle select and deselect ninja
  const handleSelectNinja = useCallback(ninja => {
    if (ninja.id === selectedNinja?.id) setSelectedNinja(null)
    else setSelectedNinja(ninja)
  }, [selectedNinja])


  // Handle all actions on closing
  const handleClose = useCallback(() => {
    setIsOpen(false)
    setStep(1)
  }, [setIsOpen, setStep])


  // Handle changes in state of modal
  const handleOpenStateChange = useCallback((newValue: boolean) => {
    if (newValue) setIsOpen(true)
    else handleClose()
  }, [handleClose, setIsOpen])


  // Handle assign stores to ninja
  const handleAssign = useCallback(() => {
    if (!selectedNinja || !selectedMissionStores.length) return

    const assignObject: AssignMissionStoresToNinjaTypeAPI = {
      missionStoresIds: selectedMissionStores.map(store => { return store.id }),
      ninjaId: selectedNinja.id
    }

    setLoadingAssign(true)

    if (selectedNinja.id === -1) {
      unassignMissionStoreFromNinja(assignObject).then(response => {
        setLoadingAssign(false)
        if (response.success)
          selectedMissionStores.length > 1
            ? toast.success(i18n.t('ToastSuccess:SuccessfullyUnassignedStoreFromNinjaPlural'))
            : toast.success(i18n.t('ToastSuccess:SuccessfullyUnassignedStoreFromNinja'))
        else
          selectedMissionStores.length > 1
            ? toast.error(i18n.t('ToastError:CoudntUnassignStoreFromNinjaPlural'))
            : toast.error(i18n.t('ToastError:CoudntUnassignStoreFromNinja'))
        handleCleanPageAfterAssign()
      })
    }
    else {
      assignMissionStoreToNinja(assignObject).then(response => {
        setLoadingAssign(false)
        if (response.success)
          selectedMissionStores.length > 1
            ? toast.success(i18n.t('ToastSuccess:SuccessfullyAssignedStoreToNinjaPlural'))
            : toast.success(i18n.t('ToastSuccess:SuccessfullyAssignedStoreToNinja'))
        else
          selectedMissionStores.length > 1
            ? toast.error(i18n.t('ToastError:CoudntAssignStoreToNinjaPlural'))
            : toast.error(i18n.t('ToastError:CoudntAssignStoreToNinja'))
        handleCleanPageAfterAssign()
      })
    }

    setSelectedNinja(null)
    setFilterText('')
    handleClose()
  }, [selectedNinja, handleClose, i18n, selectedMissionStores, handleCleanPageAfterAssign])


  return (
    <ModalWithButton
      isOpen={isOpen}
      setIsOpen={handleOpenStateChange}
      modalTitle={i18n.t('AssignNinjasModal:Title')}
      buttonText={i18n.t('AssignNinjasModal:Title')}
      disabled={selectedMissionStores.length === 0}
    >
      {step === 1 ? (
        <div className={styles.step1}>
          <div className={styles.selectNinjaToAssignContainer}>
            <TextInput
              pill
              value={filterText}
              onChange={setFilterText}
              placeholder={i18n.t('Ninjas:Filter')}
            />
            <Table<NinjaDataTypeAPI>
              loading={loading}
              columns={Object.values(NINJA_TABLE_COLUMNS)}
              items={ninjas}
              selectedItems={selectedNinja ? [selectedNinja] : []}
              getItemId={ninja => ninja.id}
              handleSelectItem={handleSelectNinja}
              pagyIndicator={pagyIndicator}
            />
          </div>
          <div className={styles.buttons}>
            <Checkbox
              handler={() => handleSelectNinja(UnassignNinjaTemplate)}
              checked={selectedNinja?.id === -1}
              text={i18n.t('MissionStore:UnassignNinjas')}
            />
            <Button
              handler={() => setStep(2)}
              title={i18n.t('Commom:Continue')}
              disabled={!selectedNinja}
            />
          </div>
        </div>
      ) : (
        <div className={styles.step2}>
          <h4>
            {selectedNinja?.id === -1 ? (
              i18n.t('AssignNinjasModal:AreYouSureUnassign')
            ) : (
              i18n.t('AssignNinjasModal:AreYouSureAssign')
            )}
          </h4>
          <div className={styles.selectedNinja}>
            {selectedNinja?.id !== -1 && (
              <h5 className={styles.info}>
                {selectedNinja?.id}
              </h5>
            )}
            <h5 className={styles.info}>
              {selectedNinja?.name}
            </h5>
            {selectedNinja?.id !== -1 && (
              <h5 className={styles.info}>
                {selectedNinja?.email}
              </h5>
            )}
          </div>
          <div className={styles.selectedMSContainer}>
            <div className={styles.selectedMSLabels}>
              <h5 className={styles.info}>
                {i18n.t('Common:Name')}
              </h5>
              <h5 className={styles.info}>
                {i18n.t('Common:City')}
              </h5>
              <h5 className={styles.info}>
                {i18n.t('Common:Address')}
              </h5>
            </div>
            <div className={styles.selectedMissionStoresList}>
              {selectedMissionStores.map(ms => (
                <div
                  className={styles.selectedMissionStoreDetail}
                  key={`selectedStoreList${ms.id}`}
                >
                  <h5 className={styles.info}>
                    {ms.store.name}
                  </h5>
                  <h5 className={styles.info}>
                    {ms.store.city}
                  </h5>
                  <h5 className={styles.info}>
                    {ms.store.address}
                  </h5>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              handler={() => setStep(1)}
              title={i18n.t('Common:GoBack')}
              variant='danger'
              fullWidth
              loading={masterLoading}
            />
            <Button
              handler={handleAssign}
              title={i18n.t('Common:Confirm')}
              fullWidth
              loading={masterLoading}
            />
          </div>
        </div>
      )}
    </ModalWithButton>
  )
}

export default AssignNinjasModalButton
