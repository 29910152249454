import React, { useCallback } from 'react'

import arrowLeft from '../../assets/icons/arrowLeft.svg'
import arrowRight from '../../assets/icons/arrowRight.svg'
import useSearchParams from '../../hooks/useSearchParams'
import { Button } from '../Button'

import styles from './PageIndicator.module.scss'


export type PageIndicatorProps = {
  currentPage: number
  lastPage: number
  loading?: boolean
  variant?: 'primary'
          | 'table'
} & ({
  isSearchParam?: false
  handler: (page: number) => void
} | {
  // If it's a search param, the handler doesn't exist
  isSearchParam: true
  handler?: undefined
})


export const PageIndicator = ({
  currentPage,
  lastPage,
  loading,
  isSearchParam,
  variant = 'primary',
  handler
}: PageIndicatorProps) => {
  const { updateSearchParams } = useSearchParams()

  const indicatorStyles: { [key: string]: string } = {
    primary: styles.primary,
    table: styles.table
  }


  const handlePageChange = useCallback((page: number) => {
    if (handler) handler(page)
    else if (isSearchParam) updateSearchParams({ page: page.toString() })
  }, [handler, isSearchParam, updateSearchParams])


  return (
    <div className={indicatorStyles[variant]}>
      <Button
        variant={variant === 'primary' ? 'primaryWhite' : 'linkGrey'}
        icon={arrowLeft}
        handler={() => handlePageChange(currentPage - 1)}
        disabled={loading || currentPage === 1}
        circle
      />
      {currentPage <= 2 ? (
        <div className={styles.bigSpacer} />
      ) : (
        <>
          <Button
            variant={variant === 'primary' ? 'primaryWhite' : 'linkGrey'}
            title='1'
            handler={() => handlePageChange(1)}
            circle
            disabled={loading}
          />
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <span className={`${styles.dots} ${loading && styles.loading}`}>
            . . .
          </span>
        </>
      )}
      {currentPage === 1 ? (
        <div className={styles.buttonSpacer} />
      ) : (
        <Button
          variant={variant === 'primary' ? 'primaryWhite' : 'linkGrey'}
          title={`${currentPage - 1}`}
          handler={() => handlePageChange(currentPage - 1)}
          circle
          disabled={loading}
        />
      )}
      <Button
        variant={variant === 'primary' ? 'primaryWhite' : 'linkGrey'}
        title={`${currentPage}`}
        handler={() => handlePageChange(currentPage)}
        circle
        disabled={loading}
      />
      {currentPage === lastPage ? (
        <div className={styles.buttonSpacer} />
      ) : (
        <Button
          variant={variant === 'primary' ? 'primaryWhite' : 'linkGrey'}
          title={`${currentPage + 1}`}
          handler={() => handlePageChange(currentPage + 1)}
          circle
          disabled={loading}
        />
      )}
      {currentPage >= lastPage - 1 ? (
        <div className={styles.bigSpacer} />
      ) : (
        <>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <span className={`${styles.dots} ${loading && styles.loading}`}>
            . . .
          </span>
          <Button
            variant={variant === 'primary' ? 'primaryWhite' : 'linkGrey'}
            title={`${lastPage}`}
            handler={() => handlePageChange(lastPage)}
            circle
            disabled={loading}
          />
        </>
      )}
      <Button
        variant={variant === 'primary' ? 'primaryWhite' : 'linkGrey'}
        icon={arrowRight}
        handler={() => handlePageChange(currentPage + 1)}
        disabled={loading || currentPage === lastPage}
        circle
      />
    </div>
  )
}
