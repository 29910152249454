import React from 'react'

import styles from './TabIndicator.module.scss'


type TabProps = {
  id: string
  title: string
  disabled?: boolean
  onClick: () => void
}

type TabIndicatorProps = {
  isSecondaryTabIndicator?: boolean
  currentTab?: string
  tabsLeft: TabProps[]
  tabsRight?: TabProps[]
  disabled?: boolean
}


export const TabIndicator = ({
  isSecondaryTabIndicator,
  currentTab,
  tabsLeft,
  tabsRight,
  disabled
}: TabIndicatorProps) => (
  <div className={`${styles.tabIndicator} ${isSecondaryTabIndicator && styles.secondaryTabIndicator}`}>
    <div className={styles.tabs}>
      {tabsLeft.map(tab => (
        <div key={`tab_${tab.id}`}>
          <button
            className={`${styles.tab} ${currentTab === tab.id && styles.tabActive}`}
            onClick={tab.onClick}
            disabled={disabled || tab.disabled}
          >
            <p>
              {tab.title}
            </p>
          </button>
        </div>
      ))}
    </div>
    {tabsRight && (
      <div className={styles.tabs}>
        {tabsRight.map(tab => (
          <div key={`tab_${tab.id}`}>
            <button
              className={`${styles.tab} ${currentTab === tab.id && styles.tabActive}`}
              onClick={tab.onClick}
              disabled={disabled || tab.disabled}
            >
              <p>
                {tab.title}
              </p>
            </button>
          </div>
        ))}
      </div>
    )}
  </div>
)
