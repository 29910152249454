import { CustomGridColDef } from "../components/RemoteTable/RemoteTable";
import { ValueType } from "../utils/formatter";

import BaseAvisoModel from "./base/BaseAvisoModel";

//#region Constants

const TABLE_NAME = 'avisoLinearExcessivo';

//#endregion Constants

export default class AvisoLinearExcessivoModel extends BaseAvisoModel{
  //#region Public Properties

  /* eslint-disable @typescript-eslint/no-inferrable-types*/
  public selloutMedio60Dias: number = 0;
  public presStock: number = 0;
  public ciclos: number = 0;
  public diasDuracaoLinear: number = 0;
  public psClassificacao60Dias: string = '';
  public percentagemDiasSemVendas60Dias: number = 0;
  //#endregion Public Properties

  //#region Static Methods

  public static getColumnsConfig = (): { [key in keyof AvisoLinearExcessivoModel]?: CustomGridColDef<AvisoLinearExcessivoModel>} => ({
    // eslint-disable-next-line i18next/no-literal-string
    ...BaseAvisoModel.getColumnsDefinition(['vendedor', 'storeName', 'descArtigo'], false),
    presStock: {
      formatType: ValueType.NUMBER
    },
    ciclos: {
      formatType: ValueType.NUMBER
    },
    selloutMedio60Dias: {
      formatType: ValueType.NUMBER
    },
    diasDuracaoLinear: {
      formatType: ValueType.NUMBER
    },
    percentagemDiasSemVendas60Dias: {
      formatType: ValueType.PERCENTAGE,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'percentagemDiasSemVendas60'
    },
    psClassificacao60Dias: {
      formatType: ValueType.STRING
    }
  })

  public static getCustomFilters = (): { [key: string]: string} => ({ table: TABLE_NAME});

  //#endregion Static Methods
}
