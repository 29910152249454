import React, { ReactNode, useCallback } from 'react'

import Cross from '../assets/icons/cross.svg'
import { Button, ButtonProps } from '../elements/Button/Button'

import styles from './ModalWithButton.module.scss'


type ModalWithButtonProps = {
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  children: ReactNode
  modalTitle: string
  buttonText: string
} & Omit<ButtonProps, 'handler' | 'children'>


export const ModalWithButton = ({
  isOpen,
  setIsOpen,
  children,
  modalTitle,
  buttonText,
  ...rest
}: ModalWithButtonProps) => {
  const handleCloseModalEvent = useCallback(e => {
    setIsOpen(false)
    e.stopPropagation()
  }, [setIsOpen])


  return (
    <>
      <Button
        title={buttonText}
        handler={() => setIsOpen(true)}
        {...rest}
      />
      {isOpen && (
        <div
          className={styles.modalWrapper}
          onClick={handleCloseModalEvent}
        >
          <div
            className={styles.content}
            onClick={e => e.stopPropagation()}
          >
            <div className={styles.header}>
              <h3>
                {modalTitle}
              </h3>
              <Button
                circle
                icon={Cross}
                variant='secondaryWhite'
                handler={() => setIsOpen(false)}
              />
            </div>
            <div className={styles.body}>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
