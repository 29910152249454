import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { ServerResponseType } from '../api'
import { MetadataTypeAPI } from '../api/types'
import AppConsts from '../Constants'
import { PageIndicator } from '../elements/PageIndicator'
import { PageIndicatorProps } from '../elements/PageIndicator/PageIndicator'

import useSearchParams from './useSearchParams'

const { DEFAULT_PAGY_ITEMS } = AppConsts

type OverflowType = 'empty_page' | 'last_page' | 'exception'
export type pagyParamsUrlType = `page=${number}&overflow=${OverflowType}&items=${number}`

type PagyParams = {
  page: number,
  count?: number,
  overflow?: OverflowType
}

export type usePagyRequestProps<ParamsType extends { pagyParamsURL: pagyParamsUrlType }, DataApiType> = {
  requestFunction: (params: ParamsType) => Promise<ServerResponseType<{ data: DataApiType[], metadata: MetadataTypeAPI }>>
  requestParams: Omit<ParamsType, 'pagyParamsURL'> & Omit<PagyParams, 'page'>
  setData: (data: DataApiType[]) => void
  errorText: string
  preRequest?: () => boolean // if this function returns true, the request will not be made
  pageIndicatorVariant?: PageIndicatorProps['variant']
} & ({
  // When we don't want to use the search params
  noSearchParamPage: number
  setNoSearchParamPage: (page) => void
} | {
  // When we want to use the search params
  noSearchParamPage?: undefined
  setNoSearchParamPage?: undefined
})


function usePagyRequest<ParamsType extends { pagyParamsURL: pagyParamsUrlType }, DataApiType, >({
  requestFunction,
  requestParams,
  setData,
  errorText,
  preRequest,
  pageIndicatorVariant,
  noSearchParamPage,
  setNoSearchParamPage
}: usePagyRequestProps<ParamsType, DataApiType>) {
  const { searchParams, updateSearchParams } = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [lastPage, setLastPage] = useState<number | null>(null)
  const [pageOverflowCorrected, setPageOverflowCorrected] = useState(false)
  const page = useMemo(() => noSearchParamPage ? noSearchParamPage : searchParams.get('page') ? Number(searchParams.get('page')) : 1, [noSearchParamPage, searchParams])


  const handlePagyRequest = useCallback(() => {
    setLoading(true)

    if (!noSearchParamPage) {
      // Verify validaty of the page and fix it if necessary
      if (isNaN(page) || page < 1) {
        updateSearchParams({ page: '1' })
        return
      } else if (lastPage && page > lastPage) {
        // If the last page is still null it means we opened the url directly and we are trusting the page exists
        // If it doesn't exist, we will react acording to the overflow type
        updateSearchParams({ page: lastPage.toString() })
        return
      }
    }

    // If the preRequest function returns true, don't make the request
    if (preRequest?.()) setLoading(false)

    // eslint-disable-next-line i18next/no-literal-string
    const pagyParamsURL: pagyParamsUrlType = `page=${page}&overflow=${requestParams.overflow || 'last_page'}&items=${requestParams.count || DEFAULT_PAGY_ITEMS}`

    requestFunction({ pagyParamsURL, ...requestParams } as ParamsType).then(response => {
      if (!response.success) {
        setLoading(false)
        toast.error(`${errorText}: ${response.error}`)
        return
      }

      setData(response.data?.data ?? [])
      setLastPage(response.data?.metadata?.lastPage ?? 1)

      // Update the page in the URL if it's different from the response
      // Wich means the page requested was overflowing and corrected by the API to the last page
      const apiPage = response.data?.metadata?.currentPage || 1
      if (page !== apiPage) {
        if (noSearchParamPage) {
          setNoSearchParamPage(apiPage)
        } else {
          setPageOverflowCorrected(true)
          updateSearchParams({ page: apiPage.toString() })
        }
      }

      setLoading(false)
    })
  }, [page, requestParams, requestFunction, setData, updateSearchParams, preRequest, lastPage, errorText, noSearchParamPage, setNoSearchParamPage])


  // Get the data when the request params change
  useEffect(() => {
    // If the page overflow was corrected, don't call the function again
    // because we already have the data for the new page
    if (pageOverflowCorrected) {
      setPageOverflowCorrected(false)
      return
    }
    handlePagyRequest()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, JSON.stringify(requestParams)]) // Only run when request params change


  const pagyIndicator = useMemo(() => {
    if (noSearchParamPage) {
      return (
        <PageIndicator
          currentPage={page}
          lastPage={lastPage || 1}
          variant={pageIndicatorVariant || 'primary'}
          handler={page => setNoSearchParamPage(page)}
        />
      )
    } else {
      return (
        <PageIndicator
          currentPage={page}
          lastPage={lastPage || 1}
          variant={pageIndicatorVariant || 'primary'}
          isSearchParam
        />
      )
    }
  }, [page, lastPage, pageIndicatorVariant, noSearchParamPage, setNoSearchParamPage])


  return {
    page,
    lastPage,
    loading,
    pagyIndicator
  }
}


export default usePagyRequest
