import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getMissionStores, GetStoresParamsTypeAPI } from '../../api'
import { MissionStoreDataTypeAPI } from '../../api/types'
import CrowdsourceIcon from '../../assets/icons/crowdsource.svg'
import { AssignConsts } from '../../Constants'
import usePagyRequest from '../../hooks/usePagyRequest'
import useSearchParams from '../../hooks/useSearchParams'
import { AssignNinjasModalButton } from '../../modals/AssignNinjasModalButton'
import { getPaths } from '../../routing/routes'
import { StoresFilters } from '../StoresFilters'
import { Table } from '../Table'

import styles from './MissionAssignNinjas.module.scss'


const { ASSIGN_TABLE_COLUMNS } = AssignConsts


export type MissionAssignNinjasProps = {
  missionId: number
  missionFinished: boolean
}


export const MissionAssignNinjas = ({
  missionId,
  missionFinished
}: MissionAssignNinjasProps) => {
  const i18n = useTranslation()
  const navigate = useNavigate()
  const { searchParams, clearSearchParams, searchParamsEmpty, updateSearchParams } = useSearchParams()

  const [missionStores, setMissionStores] = useState<MissionStoreDataTypeAPI[]>([])
  const [selectedMissionStores, setSelectedMissionStores] = useState<MissionStoreDataTypeAPI[]>([])
  const [filtersFetchingLoading, setFiltersFetchingLoading] = useState(false)


  const storesParams = useMemo(() => {
    const storeNameOrCode = searchParams.get('storeNameOrCode') || undefined
    const placeTypeIds = searchParams.getAll('storeTypes')?.map(Number)
    const storeChainIds = searchParams.getAll('storeChains')?.map(Number)
    const storeGroupIds = searchParams.getAll('storeGroups')?.map(Number)
    const status = searchParams.getAll('status')
    const cities = searchParams.getAll('cities')
    return { storeNameOrCode, placeTypeIds, storeChainIds, storeGroupIds, status, cities }
  }, [searchParams])


  // Deals with fetching the mission stores and paginating them
  const { loading, pagyIndicator } = usePagyRequest<GetStoresParamsTypeAPI, MissionStoreDataTypeAPI>({
    requestFunction: getMissionStores,
    requestParams: { missionId, ...storesParams, count: 10 },
    setData: setMissionStores,
    errorText: i18n.t('Stores:ErrorFetchingStores'),
    pageIndicatorVariant: 'table'
  })


  // Check if the page is loading
  const masterLoading = useMemo(() => loading || filtersFetchingLoading, [loading, filtersFetchingLoading])


  // Handle selecting and unselecting stores
  const handleSelectStore = useCallback((store: MissionStoreDataTypeAPI) => {
    setSelectedMissionStores(old => old.map(s => s.id).includes(store.id)
      ? old.filter(s => s.id !== store.id)
      : [...old, store]
    )
  }, [])


  // Handle selecting and unselecting ALL stores
  const handleSelectAllStores = useCallback((select: boolean) => {
    if (select) {
      const selectedMissionStoresIds = selectedMissionStores.map(ms => ms.id)
      const storesToAdd = missionStores.filter(ms => !selectedMissionStoresIds.includes(ms.id))
      setSelectedMissionStores(old => [...storesToAdd, ...old])
    } else {
      const missionStoresIds = missionStores.map(ms => ms.id)
      setSelectedMissionStores(old => old.filter(ms => !missionStoresIds.includes(ms.id)))
    }
  }, [selectedMissionStores, missionStores])


  // For modal to use after assigning
  const handleCleanPageAfterAssign = useCallback(() => {
    // This forces the usePagyParams to fetch updated mission stores
    // to prevent users from accessing the page if the mission has already ended
    if (searchParamsEmpty()) updateSearchParams({ page: '-1' })
    else clearSearchParams(true)
    setSelectedMissionStores([])
    setMissionStores([])
  }, [searchParamsEmpty, updateSearchParams, clearSearchParams])


  useEffect(() => {
    if (missionFinished)
      navigate(getPaths.brands.mission.details(missionId))
  }, [navigate, missionId, missionFinished])


  return (
    <div className={styles.container}>
      <StoresFilters
        pill
        loading={masterLoading}
        setLoading={setFiltersFetchingLoading}
      />
      <Table<MissionStoreDataTypeAPI>
        loading={masterLoading}
        columns={Object.values(ASSIGN_TABLE_COLUMNS)}
        items={missionStores}
        selectedItems={selectedMissionStores}
        getItemId={store => store.id}
        pagyIndicator={pagyIndicator}
        handleSelectItem={handleSelectStore}
        handleSelectAllItems={handleSelectAllStores}
        isItemDisabled={store => !!store.ninjaWillUnassignAt}
        labels={
          <div className={styles.crowdsourceLabel}>
            <img
              src={CrowdsourceIcon}
              className={styles.crowdsourceIcon}
            />
            <span>
              {i18n.t('MissionStores:CrowdsourceMissions')}
            </span>
          </div>
        }
      />
      <AssignNinjasModalButton
        selectedMissionStores={selectedMissionStores}
        handleCleanPageAfterAssign={handleCleanPageAfterAssign}
      />
    </div>
  )
}
