import { MissionStoreDataTypeAPI, NinjaDataTypeAPI } from './api/types'
import { FiltersToFetchStoresType } from './components/StoresFilters/StoresFilters'
import i18n from './localization'

const WEBSITE_URL = 'https://brandsandninjas.com/'
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL


export default {
  REACT_APP_API_BASE_URL,
  WEBSITE_URL,
  DEFAULT_PAGY_ITEMS: 20
}

// ##################################
// ##
// ##     WIZARD CONSTANTS
// ##

const MAX_CHARS_MISSION_DESCRIPTION = 500
const NUMBER_OF_STORES_PER_PAGE = 50

const BINARY_ANSWERS = {
  no: { id: 'no', label: i18n.t('Common:No') },
  yes: { id: 'yes', label: i18n.t('Common:Yes') }
} as const
export type BinaryAnswerType = typeof BINARY_ANSWERS[keyof typeof BINARY_ANSWERS]

const QUESTION_TYPES = {
  binary: {
    id: 'binary',
    label: i18n.t('MissionWizard:QuestionTypeBinary'),
    hasOptions: false,
    expectedAnswerEnabled: true,
    defaultExpectedAnswer: BINARY_ANSWERS.no.id,
    validationEnabled: true
  },
  numeric: {
    id: 'numeric',
    label: i18n.t('MissionWizard:QuestionTypeNumeric'),
    hasOptions: false,
    expectedAnswerEnabled: true,
    defaultExpectedAnswer: '0',
    validationEnabled: false
  },
  free_text: {
    id: 'free_text',
    label: i18n.t('MissionWizard:QuestionTypeFreeText'),
    hasOptions: false,
    expectedAnswerEnabled: false,
    defaultExpectedAnswer: null,
    validationEnabled: false
  },
  multiple_choice: {
    id: 'multiple_choice',
    label: i18n.t('MissionWizard:QuestionTypeMultipleChoice'),
    hasOptions: true,
    expectedAnswerEnabled: false,
    defaultExpectedAnswer: null,
    validationEnabled: false
  },
  multiple_answer: {
    id: 'multiple_answer',
    label: i18n.t('MissionWizard:QuestionTypeMultipleAnswer'),
    hasOptions: true,
    expectedAnswerEnabled: false,
    defaultExpectedAnswer: null,
    validationEnabled: false
  }
} as const
export type QuestionTypesType = typeof QUESTION_TYPES[keyof typeof QUESTION_TYPES]

const DEFAULT_ANSWER_OPTIONS = [{ order: 0, text: '' }, { order: 1, text: '' }]

const DEFAULT_NEW_QUESTION = () => ({
  order: 0,
  text: '',
  shortName: '',
  questionType: QUESTION_TYPES.binary.id,
  expectedAnswer: null,
  image: null,
  requiresPhotoValidation: false,
  answerOptions: JSON.parse(JSON.stringify(DEFAULT_ANSWER_OPTIONS)),
  toValidate: false
})


export const WizardConsts = {
  BINARY_ANSWERS,
  DEFAULT_NEW_QUESTION,
  MAX_CHARS_MISSION_DESCRIPTION,
  NUMBER_OF_STORES_PER_PAGE,
  QUESTION_TYPES
}


// ##################################
// ##
// ##    ANSWERS CONSTANTS
// ##

const ANSWERS_STATUSES = {
  all: {
    id: 'all',
    label: i18n.t('Answers:Status:All')
  },
  to_validate: {
    id: 'to_validate',
    label: i18n.t('Answers:Status:ToValidate')
  },
  accepted: {
    id: 'accepted',
    label: i18n.t('Answers:Status:Accepted')
  },
  denied: {
    id: 'denied',
    label: i18n.t('Answers:Status:Denied')
  }
} as const
export type AnswersStatusesType = typeof ANSWERS_STATUSES[keyof typeof ANSWERS_STATUSES]

const COMPLETED_ERRORS = {
  submitted_after_mission_end: {
    id: 'submitted_after_mission_end',
    label: i18n.t('Answers:CompletedError:SubmittedAfterMissionEnd')
  },
  answered_after_mission_end: {
    id: 'answered_after_mission_end',
    label: i18n.t('Answers:CompletedError:AnsweredAfterMissionEnd')
  },
  outside_geofence: {
    id: 'outside_geofence',
    label: i18n.t('Answers:CompletedError:OutsideGeofence')
  }
} as const

const CANT_DO_ERRORS = {
  closed_store: {
    id: 'closed_store',
    label: i18n.t('Answers:CantDoError:ClosedStore'),
    text_prop: null
  },
  access_denied: {
    id: 'access_denied',
    label: i18n.t('Answers:CantDoError:AccessDenied'),
    text_prop: null
  },
  def_closed_store: {
    id: 'def_closed_store',
    label: i18n.t('Answers:CantDoError:DefClosedStore'),
    text_prop: null
  },
  temp_closed_store: {
    id: 'temp_closed_store',
    label: i18n.t('Answers:CantDoError:TempClosedStore'),
    text_prop: null
  },
  non_horeca_submission_error: {
    id: 'non_horeca_submission_error',
    label: i18n.t('Answers:CantDoError:NonHorecaSubmissionError'),
    text_prop: 'nonHorecaSubmissionError'
  },
  other_error: {
    id: 'other_error',
    label: i18n.t('Answers:CantDoError:OtherError'),
    text_prop: 'userReportedError'
  }
} as const


export const AnswersConsts = {
  ANSWERS_STATUSES,
  CANT_DO_ERRORS,
  COMPLETED_ERRORS
}

// ##################################
// ##
// ##    ASSIGN CONSTANTS 
// ##

const ASSIGN_TABLE_COLUMNS = {
  name: {
    id: 'name',
    label: i18n.t('Common:Name'),
    getValue: (missionStore: MissionStoreDataTypeAPI) => missionStore.store.name
  },
  address: {
    id: 'address',
    label: i18n.t('MissionStores:Address'),
    getValue: (missionStore: MissionStoreDataTypeAPI) => missionStore.store.address
  },
  postalCode: {
    id: 'postalCode',
    label: i18n.t('MissionStores:PostalCode'),
    getValue: (missionStore: MissionStoreDataTypeAPI) => missionStore.store.postalCode
  },
  city: {
    id: 'city',
    label: i18n.t('MissionStores:City'),
    getValue: (missionStore: MissionStoreDataTypeAPI) => missionStore.store.city
  },
  country: {
    id: 'country',
    label: i18n.t('MissionStores:Country'),
    getValue: (missionStore: MissionStoreDataTypeAPI) => missionStore.store.country
  },
  ninja: {
    id: 'ninja',
    label: i18n.t('Common:Ninja'),
    getValue: (missionStore: MissionStoreDataTypeAPI) => missionStore.ninja || '-'
  },
  unassignAt: {
    id: 'unassignAt',
    label: i18n.t('MissionStores:UnassignAt'),
    getValue: (missionStore: MissionStoreDataTypeAPI) => missionStore.ninjaWillUnassignAt ? missionStore.ninjaWillUnassignAt : '-',
  },
  stauts: {
    id: 'status',
    label: i18n.t('MissionStores:Status'),
    getValue: (missionStore: MissionStoreDataTypeAPI) => {
      if (!missionStore.ninja) {
        return i18n.t('MissionStores:NotAssigned')
      } else if (missionStore.status === 'analysis') {
        return i18n.t('MissionStores:ToValidate')
      } else if (missionStore.status === 'completed') {
        return i18n.t('MissionStores:Concluded')
      } else {
        if (missionStore.ninjaWillUnassignAt) {
          return i18n.t('MissionStores:CrowdsourceAssigned')
        } else {
          return i18n.t('MissionStores:ManuallyAssigned')
        }
      }
    }
  }
} as const

const NINJA_TABLE_COLUMNS = {
  id: {
    id: 'id',
    label: i18n.t('Common:ID'),
    flexSpace: 1
  },
  name: {
    id: 'name',
    label: i18n.t('Common:Name'),
    flexSpace: 4
  },
  email: {
    id: 'email',
    label: i18n.t('Common:Email'),
    flexSpace: 4
  }
}

const ASSIGN_STATUS_FILTERS = {
  notAssigned: {
    id: 'not_assigned',
    label: i18n.t('MissionStores:NotAssigned')
  },
  manuallyAssigned: {
    id: 'manually_assigned',
    label: i18n.t('MissionStores:ManuallyAssigned')
  },
  crowdsourceAssigned: {
    id: 'crowdsource_assigned',
    label: i18n.t('MissionStores:CrowdsourceAssigned')
  },
  toValidate: {
    id: 'to_validate',
    label: i18n.t('MissionStores:ToValidate')
  },
  concluded: {
    id: 'concluded',
    label: i18n.t('MissionStores:Concluded')
  }
} as const

export const EmptyFiltersToFetchStores: FiltersToFetchStoresType = {
  storeTypes: [],
  storeChains: [],
  storeGroups: [],
  cities: [],
  storeNameOrCode: ''
}

export const UnassignNinjaTemplate: NinjaDataTypeAPI = {
  id: -1,
  name: i18n.t('MissionStores:WithoutNinja'),
  email: ''
}

export type StoreStatusTypeAPI = typeof ASSIGN_STATUS_FILTERS[keyof typeof ASSIGN_STATUS_FILTERS]


export const AssignConsts = {
  ASSIGN_TABLE_COLUMNS,
  ASSIGN_STATUS_FILTERS,
  NINJA_TABLE_COLUMNS
}
