import {
  Navigate,
  Routes,
  Route,
  Outlet
} from 'react-router-dom'

import { CheckIdParam } from '../components/shared/CheckIdParam'
import { CheckLiteralStringParam } from '../components/shared/CheckLiteralStringParam'
import { CheckPermission } from '../components/shared/CheckPermission'
import { RedirectWithId } from '../components/shared/RedirectWithId'
import i18n from '../localization'
import { Dashboard } from '../pages/Dashboard'
import { DashboardTabsTypeArray } from '../pages/Dashboard/Dashboard'
import { MissionPage } from '../pages/Mission'
import { MissionsPage } from '../pages/Missions'
import { NoPermissions } from '../pages/NoPermissions'
import { RouteNotFound } from '../pages/RouteNotFound'
import { Step1 } from '../pages/Wizard/Step1'
import { Step2 } from '../pages/Wizard/Step2'
import { Step3 } from '../pages/Wizard/Step3'
import { Step4 } from '../pages/Wizard/Step4'
import { Wizard } from '../pages/Wizard/Wizard'

import { getPaths } from './routes'


const AppRouter = () => {
  return (
    <Routes>
      <Route
        path={getPaths.brands._base}
        element={<Outlet />}
      >
        {/* NO PERMISSIONS -> */}
        <Route
          path='no_permissions'
          element={<NoPermissions />}
        />
        {/* <- NO PERMISSIONS */}
        {/* MISSIONS PAGES -> */}
        <Route
          path={getPaths.brands.missions._base}
          element={<CheckPermission permission={'missions_viewer'} />}
        >
          <Route
            path='ongoing'
            element={<MissionsPage state={'ongoing'} />}
          />
          <Route
            path='before_start'
            element={<MissionsPage state={'before_start'} />}
          />
          <Route
            path='completed'
            element={<MissionsPage state={'completed'} />}
          />
          <Route
            path='in_the_making'
            element={<MissionsPage state={'in_the_making'} />}
          />
          <Route
            path=''
            element={<Navigate to={getPaths.brands.missions.ongoing} replace />}
          />
        </Route>
        {/* <- MISSIONS PAGES */}
        {/* INDIVIDUAL MISSION PAGES -> */}
        <Route
          path={getPaths.brands.mission._base}
          element={<CheckPermission permission={'missions_viewer'} />}
        >
          <Route
            path=':id'
            element={
              <CheckIdParam
                errorText={i18n.t('ToastError:MissionIdNotFound')}
                goToText={i18n.t('GoTo:MissionsPage')}
                redirectPath={getPaths.brands.missions.ongoing}
              />
            }
          >
            <Route
              path='details'
              element={<MissionPage info={'details'} />}
            />
            <Route
              path='stores_summary'
              element={<MissionPage info={'stores_summary'} />}
            />
            <Route
              path='stats'
              element={<MissionPage info={'stats'} />}
            />
            <Route
              path='answers'
              element={<MissionPage info={'answers'} />}
            />
            <Route
              path='ninjas_assign'
              element={<MissionPage info={'ninjas_assign'} />}
            />
            <Route
              path=''
              element={
                <RedirectWithId
                  pathBeforeId={getPaths.brands.mission.index}
                  pathAfterId='/details'
                />
              }
            />
          </Route>
        </Route>
        {/* <- INDIVIDUAL MISSION PAGES */}
        {/* WIZARD PAGES -> */}
        <Route
          path={getPaths.brands.wizard._base}
          element={<CheckPermission permission={'mission_creator'} />}
        >
          <Route
            path=':id'
            element={(
              <CheckIdParam
                errorText={i18n.t('ToastError:MissionIdNotFound')}
                goToText={i18n.t('GoTo:MissionsPage')}
                redirectPath={getPaths.brands.missions.ongoing}
              >
                {id => <Wizard missionId={id} />}
              </CheckIdParam>
            )}>
            <Route
              path='step_1'
              element={<Step1 />}
            />
            <Route
              path='step_2'
              element={<Step2 />}
            />
            <Route
              path='step_3'
              element={<Step3 />}
            />
            <Route
              path='step_4'
              element={<Step4 />}
            />
          </Route>
        </Route>
        <Route
          path=''
          element={<Navigate to={getPaths.brands.missions.ongoing} replace />}
        />
        <Route
          path='*'
          element={
            <RouteNotFound
              goToText={i18n.t('GoTo:MissionsPage')}
              route={getPaths.brands.missions.ongoing}
            />
          }
        />
        {/* <- WIZARD PAGES */}
        {/* DASHBOARD PAGES -> */}
        <Route
          path={getPaths.brands.dashboard._base}
          element={<CheckPermission permission={'dashboard_viewer'} />}
        >
          <Route
            path=':tab'
            element={
              <CheckLiteralStringParam
                param='tab'
                options={DashboardTabsTypeArray}
                errorText={i18n.t('ToastError:DashboardTabNotFound')}
                goToText={i18n.t('GoTo:Dashboard')}
                redirectPath={getPaths.brands.dashboard.tab('main_dashboard')}
              >
                <Dashboard />
              </CheckLiteralStringParam>
            }
          />
        </Route>
        {/* <- DASHBOARD PAGES */}
      </Route>
      {/* If we try to access pages from outside (not logged in), we redirect */}
      <Route
        path={`${getPaths.managers._base}/*`}
        element={<Navigate to={getPaths.brands.missions.ongoing} replace />}
      />
      {/* Otherwise, page not found */}
      <Route
        path='*'
        element={
          <RouteNotFound
            goToText={i18n.t('GoTo:MissionsPage')}
            route={getPaths.brands.missions.ongoing}
          />
        }
      />
    </Routes>
  )
}

export default AppRouter
