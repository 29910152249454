import denormalize from '@weareredlight/denormalize_json_api'
import saveAs from 'file-saver'
import { Cookies } from 'react-cookie'

import { ChartRawData } from '../components/Chart/ChartDefinition'
import { DashboardChartFilters } from '../components/DashboardChart/DashboardChart'
import { DashboardSelectorFilters } from '../components/DashboardFilters/DashboardFilters'
import { DashboardKpiFilters, KpiData } from '../components/KpiCard/KpiCard'
import { ExportTableFilters, TableFilters } from '../components/RemoteTable/RemoteTable'
import AppConsts, { AnswersConsts } from '../Constants'
import { RemoteDropdownData } from '../elements/Dropdown/RemoteDropdown'
import { pagyParamsUrlType } from '../hooks/usePagyRequest'
import i18n from '../localization'
import { getPaths } from '../routing/routes'
import { formatCamelToSnakeCase } from '../utils/formatter'
import { storesFiltersSearchParams } from '../utils/searchParamsMakers'
import { isEmptyOrWhiteSpace } from '../utils/validator'


import { camelizeReviver, decamelizeDeep, serialize } from './networkUtils'
import {
  LoginResponseTypeAPI,
  LoginTypeAPI,
  MissionStoreAnswersTypeAPI,
  MissionsTypeAPI,
  MissionTypeAPI,
  MissionUpdateTypeAPI,
  PlaceOrStoreTypeAPI,
  StoresAnswersHistoryTypeAPI,
  StoreCitiesTypeAPI,
  StoreFiltersTypeAPI,
  TableTypeAPI,
  UserDataTypeAPI,
  MissionStoresTypeAPI,
  NinjasTypeAPI,
  AssignMissionStoresToNinjaTypeAPI,
  GetPlacesOrStoresFiltersType,
  GetStoresFiltersTypeAPI
} from './types'


//#region Constants

// README: when everything is in react, this will change (at least the const name to BASE_URL_API or something)
const BASE_URL = `${AppConsts.REACT_APP_API_BASE_URL}brands/api/v1`
const BASE_URL_SESSIONS = AppConsts.REACT_APP_API_BASE_URL
const DEBUG_ON = false
const DEFAULT_EXPORT_NAME = 'export'
const CSV_EXTENSION = 'csv'

//#endregion Constants

// ##################################
// ##
// ##     Auth
// ##

export const login = async (data: LoginTypeAPI) => request<LoginResponseTypeAPI>('POST', '/managers/sign_in', data, { baseUrl: BASE_URL_SESSIONS, multipart: true, isLogin: true })

export const logout = async () => request<boolean>('DELETE', '/managers/sign_out', undefined, { baseUrl: BASE_URL_SESSIONS })


// ##################################
// ##
// ##     Managers
// ##

export const getUserData = async () => request<UserDataTypeAPI>('GET', '/managers/info')


// ##################################
// ##
// ##     Missions
// ##

export type missionState = 'ongoing' | 'before_start' | 'completed' | 'in_the_making'

export type GetMissionsParamsType = {
  state: missionState
  name: string | null
  pagyParamsURL: pagyParamsUrlType
}

export const getMissions = async (params: GetMissionsParamsType) => request<MissionsTypeAPI>('GET', `/missions?state=${params.state}${params.name ? `&name=${params.name}` : ''}&${params.pagyParamsURL}`)

export const exportMissionData = async (
  missionId: number,
  type: 'csv' | 'pdf' | 'img'
) => request('POST', `/missions/${missionId}/export?type=${type}`)


// ##################################
// ##
// ##     Answers
// ##

export type AnswersStatusType = typeof AnswersConsts.ANSWERS_STATUSES[keyof typeof AnswersConsts.ANSWERS_STATUSES]['id']

export type GetAnswersParamsType = {
  missionId: number,
  status: AnswersStatusType,
  store: string | null
  pagyParamsURL: pagyParamsUrlType,
}

export const getAnswers = async (params: GetAnswersParamsType) => request<StoresAnswersHistoryTypeAPI>('GET', `/missions/${params.missionId}/answers?status=${params.status}${params.store ? `&store_filter=${params.store}` : ''}&${params.pagyParamsURL}`)

export const acceptAnswer = async (missionStoreId: number) => request<MissionStoreAnswersTypeAPI>('PUT', `/answers/${missionStoreId}/accept`)

export const denyAnswer = async (missionStoreId: number) => request<MissionStoreAnswersTypeAPI>('PUT', `/answers/${missionStoreId}/deny`)


// ##################################
// ##
// ##     Assign Ninjas
// ##

export type GetStoresParamsTypeAPI = {
  pagyParamsURL: pagyParamsUrlType
} & Omit<GetStoresFiltersTypeAPI, 'page'>

export const getMissionStores = async (params: GetStoresParamsTypeAPI) => request<MissionStoresTypeAPI>('GET', `/mission_stores/${params.missionId}${storesFiltersSearchParams(params)}&${params.pagyParamsURL}`)

export type GetNinjasParamsTypeAPI = {
  filter: string | null
  pagyParamsURL: pagyParamsUrlType
}

export const getNinjas = async (data: GetNinjasParamsTypeAPI) => request<NinjasTypeAPI>('GET', `/ninjas?${data.pagyParamsURL}&filter=${data.filter}`)

export const assignMissionStoreToNinja = async (data: AssignMissionStoresToNinjaTypeAPI) => request('PUT', '/mission_stores/assign', data, { multipart: true })

export const unassignMissionStoreFromNinja = async (data: AssignMissionStoresToNinjaTypeAPI) => request('PUT', '/mission_stores/unassign', data, { multipart: true })


// ##################################
// ##
// ##     Wizard
// ##

export const createMission = async () => request<MissionTypeAPI>('POST', '/wizard')

export const createMissionCopy = async (missionId: MissionTypeAPI['id']) => request<MissionTypeAPI>('POST', `/wizard/${missionId}`)

export const updateMission = async (id: number, data: MissionUpdateTypeAPI) => request<MissionTypeAPI>('PUT', `/wizard/${id}`, data, { multipart: true })

export const getMission = async (missionId: MissionTypeAPI['id']) => request<MissionTypeAPI>('GET', `/wizard/${missionId}`)

export const getPlacesOrStores = async (data: GetPlacesOrStoresFiltersType) => request<PlaceOrStoreTypeAPI>('GET', `/wizard/stores${storesFiltersSearchParams(data)}`)

export const getStoreFilters = async () => request<StoreFiltersTypeAPI>('GET', '/wizard/filters')

export const getStoreCities = async (name: string) => request<StoreCitiesTypeAPI>('GET', `/wizard/cities?name=${name}`)


// ##################################
// ##
// ##     Dashboards
// ##

export type OrderByType = 'ASC' | 'DESC'

const formatFiltersRecursive = (filters: object, prefix?: string) => Object.entries(filters)
                                                      .filter(([, value]) => !isEmptyOrWhiteSpace(value))
                                                      .reduce((acc: string[], [key, value]: [string, unknown]) => {
                                                        const param = formatCamelToSnakeCase(key);

                                                        if(Array.isArray(value)) {
                                                          return [
                                                            ...acc,
                                                            ...value.reduce((innerAcc: string[], innerValue: unknown) => {
                                                              const innerParam = `${param}[]`;
                                                              switch(typeof innerValue) {
                                                                case 'object':
                                                                  return [...innerAcc, ...formatFiltersRecursive(innerValue as object, innerParam)];
                                                                default:
                                                                  return [...innerAcc, `${innerParam}=${encodeURIComponent(innerValue as string | number)}`];
                                                              }
                                                          }, [])];
                                                        }

                                                        return [...acc, `${prefix ?? ''}${prefix ? `[${param}]` : param}=${encodeURIComponent(value as string | number)}`]
                                                      }, [])

const formatFilters = (filters: object) => formatFiltersRecursive(filters).join('&')

export const getDashboardTable = async <T>(filters: TableFilters) => request<TableTypeAPI<T>>('GET', `/dashboard/table?${formatFilters(filters)}`)

export const exportDashboardTable = async (filters: ExportTableFilters, title?: string) => request<Blob>('POST', `/dashboard/table/export`, filters, {fileDownload: true, multipart: true}).then(({data}: ServerResponseType<Blob>) => {
  saveAs(data as Blob, `${title ?? DEFAULT_EXPORT_NAME}.${CSV_EXTENSION}`);
})

export const getDashboardSelector = async (filters: DashboardSelectorFilters) => request<RemoteDropdownData[]>('GET', `/dashboard/selector?${formatFilters(filters)}`)

export const getDashboardKpi = async (filters: DashboardKpiFilters) => request<KpiData>('GET', `/dashboard/kpi?${formatFilters(filters)}`)

export const getDashboardChart = async (filters: DashboardChartFilters) => request<ChartRawData[]>('GET', `/dashboard/chart?${formatFilters(filters)}`)


// ##################################
// ##
// ##     Main function
// ##

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD'

export type ServerResponseType<T> = {
  success: false
  data?: undefined
  error: string
  response: Response
} | {
  success: true
  data: T
  error?: undefined
  response: Response
}

interface RequestOptions {
  multipart?: boolean
  isLogin?: boolean
  fileDownload?: boolean
  baseUrl?: string
}


const request = async <T>(method: MethodType, endpoint: string, body?: unknown, { baseUrl, multipart, fileDownload, isLogin }: RequestOptions = {}): Promise<ServerResponseType<T>> => {
  const cookies = new Cookies()
  let email = cookies.get('email')
  let authenticationToken = cookies.get('authenticationToken')

  if (!email || !authenticationToken) {
    email = localStorage.getItem('email')
    authenticationToken = localStorage.getItem('authenticationToken')
  }

  const headers: HeadersInit = [
    ['X-MANAGER-EMAIL', email],
    ['X-MANAGER-TOKEN', authenticationToken]
  ]

  const options = { method, headers }
  if (body) {
    // @ts-expect-error -> body can be undefined
    options.body = multipart
      ? serialize(decamelizeDeep(body))
      : JSON.stringify(decamelizeDeep(body))
  }

  let response = {} as Response

  try {
    response = await fetch(`${baseUrl || BASE_URL}${endpoint}`, options)
  } catch (error: unknown) {
    DEBUG_ON && console.warn(error)
    return {
      success: false,
      // @ts-expect-error -> error can be undefined
      error: error.message ?? i18n.t('Network:Error'),
      response,
    }
  }

  if (response.status === 401) {
    cookies.remove('name')
    cookies.remove('email')
    cookies.remove('authenticationToken')
    localStorage.removeItem('authenticationToken')
    if (!isLogin)
      window.location.replace(getPaths.managers.signIn)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let jsonResponse: any = null

  if (fileDownload) {
    jsonResponse = await response.blob();
  } else {
    try {
      const textContent = await response.text()
      jsonResponse = JSON.parse(textContent, camelizeReviver)
    } catch {
      jsonResponse = {}
    }
  }

  DEBUG_ON && console.debug('#################################################')
  DEBUG_ON && console.debug('### REQUEST', method, endpoint, email || '[no user]', authenticationToken || '[no token]')
  DEBUG_ON && body && console.debug('### REQUEST body', body)
  DEBUG_ON && console.debug('### REQUEST', response.ok ? 'OK' : 'FAILED', response.status)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let data: any = null
  if (jsonResponse?.data && jsonResponse?.metadata && jsonResponse?.dataTypes) {
    data = {
      data: denormalize(jsonResponse.data),
      dataTypes: denormalize(jsonResponse.dataTypes),
      metadata: jsonResponse.metadata,
    }
  } else if (jsonResponse?.data && jsonResponse?.metadata) {
    data = {
      data: denormalize(jsonResponse.data),
      metadata: jsonResponse.metadata
    }
  } else {
    data = denormalize(jsonResponse)
  }

  if (response.ok) {
    DEBUG_ON && data && console.debug('### DATA', JSON.stringify(data).slice(0, 500))
    DEBUG_ON && console.debug('#################################################')
    return { success: true, data, response }
  }
  DEBUG_ON && console.debug('### ERROR', data?.errors)
  DEBUG_ON && console.debug('#################################################')

  const dataError = data
    ? Array.isArray(data?.errors)
      ? `${data.errors[0].detail || data.errors[0].title || data.errors[0]}`
      : JSON.stringify(data.errors)
    : ''

  return {
    response,
    success: false,
    error: dataError ?? i18n.t('Network:UnknowError', { code: response.status })
  }
}
