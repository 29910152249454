import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'

import { ServerResponseType } from '../../api/index'
import ArrowDown from '../../assets/icons/arrowDown.svg'
import ArrowUp from '../../assets/icons/arrowUp.svg'
import useSearchParams from '../../hooks/useSearchParams'
import { Button } from '../Button'
import { Scroll } from '../Scroll'
import { TextInput } from '../TextInput'

import styles from './MultiDropdownFetch.module.scss'


export type MultiDropdownFetchProps<T> = {
  selected: T[]
  fetcher: (name: string) => Promise<ServerResponseType<{ [dataKey: string]: T[] }>>
  dataKey: string
  getOptionLabel: (item: T) => string
  getOptionId: (item: T) => string
  title?: string
  disabled?: boolean
  height?: string
  pill?: boolean
  customWidth?: string
  placeholder?: string
} & ({ // Conditional props below
  onChange: (v: T[]) => void
  searchParamName?: undefined
} | {
  // If it's a search param, the onChange doesn't exist
  onChange?: undefined
  searchParamName: string
})


export const MultiDropdownFetch = <T,>({
  selected,
  fetcher,
  dataKey,
  onChange,
  getOptionLabel,
  getOptionId,
  title,
  disabled,
  height,
  pill,
  searchParamName,
  customWidth,
  placeholder
}: MultiDropdownFetchProps<T>) => {
  const i18n = useTranslation()
  const { updateSearchParams } = useSearchParams()

  const [options, setOptions] = useState<T[]>([])
  const [filter, setFilter] = useState('')
  const [isDroped, setIsDroped] = useState(false)
  const [isHoverTopContainer, setIsHoverTopContainer] = useState(false)
  const [isHoverOptions, setIsHoverOptions] = useState(false)

  const containerRef = useRef(null)
  const droppedListRef = useRef(null)

  const selectedIds = useMemo(() => selected.map(getOptionId), [selected, getOptionId])


  // Returns the label for the dropdown with info of the selected options
  const dropdownLabel = useMemo(() => {
    const countSelected = selected.length
    if (countSelected === 0) return ''
    return getOptionLabel(selected[0])
  }, [selected, getOptionLabel])


  // Returns the number of selected options for the dropdown
  const dropdownLabelCount = useMemo(() => {
    const countSelected = selected.length
    if (countSelected <= 1) return ''
    return `(${countSelected})`
  }, [selected])


  // Handles the click outside the dropdown logic
  useEffect(() => {
    if (isHoverTopContainer) return () => null

    const handleOutsideClick = () => {
      if (!isHoverOptions) setIsDroped(false)
    }

    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [isHoverOptions, isHoverTopContainer])


  // Handle adding or removing an option from the selected list
  const updateSelectedList = useCallback((opt: T) => {
    const optId = getOptionId(opt)
    const newSelected = selectedIds.includes(optId)
      ? selected.filter(s => getOptionId(s) !== optId)
      : [...selected, opt]

    if (onChange) {
      onChange(newSelected)
    } else if (searchParamName) {
      const newSelectedIds = newSelected.map(getOptionId)
      updateSearchParams({ [searchParamName]: newSelectedIds })
    }
  }, [selected, selectedIds, onChange, getOptionId, searchParamName, updateSearchParams])


  // Fetch the options when the filter changes after X time
  useEffect(() => {
    const fetcherTimeout = setTimeout(() => {
      if (filter === '') {
        setOptions([])
        return
      }
      fetcher(filter).then(response => {
        if (response.data && response.data[dataKey]) setOptions(response.data[dataKey])
        else setOptions([])
      })
    }, 500)
    return () => clearTimeout(fetcherTimeout)
  }, [fetcher, selected, filter, dataKey])


  useEffect(() => {
    isDroped ? null : setFilter('')
  }, [isDroped])


  return (
    <div
      className={`${styles.dropdown} ${disabled && styles.disabled}`}
      style={{ width: customWidth }}
    >
      <button
        className={`${styles.topContainer} ${disabled && styles.disabled} ${pill && styles.pill}`}
        onClick={() => setIsDroped(!isDroped)}
        ref={containerRef}
        onMouseEnter={() => setIsHoverTopContainer(true)}
        onMouseLeave={() => setIsHoverTopContainer(false)}
      >
        <div className={styles.info}>
          {title && (
            <h4 className={styles.title}>
              {title}
            </h4>
          )}
          <span className={styles.text}>
            {dropdownLabel || placeholder}
          </span>
        </div>
        <div className={styles.rightInfo}>
          <span className={styles.counter}>
            {dropdownLabelCount}
          </span>
          <img
            className={styles.imgContainer}
            src={isDroped ? ArrowUp : ArrowDown}
            alt='DropdownArrow'
            draggable={false}
          />
        </div>
      </button>
      {isDroped && (
        <div className={styles.droppedContainer}>
          <div className={styles.droppedTopBackground} />
          <div
            ref={droppedListRef}
            className={styles.dropedList}
            onMouseEnter={() => setIsHoverOptions(true)}
            onMouseLeave={() => setIsHoverOptions(false)}
          >
            <TextInput
              value={filter}
              onChange={v => setFilter(v)}
              placeholder={i18n.t('Common:SearchHere')}
              noBorders
            />
            <Scroll<T>
              options={options}
              height={height}
              variant='dropdown'
              getOptionKey={option => getOptionId(option)}
              getOptionComponent={opt => (
                <Button
                  title={getOptionLabel(opt)}
                  handler={() => updateSelectedList(opt)}
                  fullWidth
                  textLeft
                  checkbox
                  checked={selectedIds.includes(getOptionId(opt))}
                  variant='dropdown'
                />
              )}
            />
          </div>
        </div>
      )}
    </div>
  )
}
