import { Box, LinearProgress } from "@mui/material";
import { GridCellParams, GridColDef, GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid";

import { CustomGridColDef, ExportTableFiltersColumn, GridCellFormat, GridCellWidgetType } from "../components/RemoteTable/RemoteTable";
import i18n from "../localization";

import { formatTitle, formatValueByType, ValueType } from "./formatter";
import { isEmptyOrWhiteSpace } from "./validator";

//#region Enums

export enum TableColorType {
  RED = '#EF8C8C',
  ORANGE = '#F2A86F',
  GREEN = '#88BF4D',
  YELLOW = '#F9D45C'
}

//#endregion Enums

//#region Constants

const LINEAR_PROGRESS_DETERMINATE = 'determinate';

//#endregion Constants

export const extractGridExportColumns = (columns: GridColDef[]): ExportTableFiltersColumn[] => columns.map(({field, headerName}: GridColDef) => ({
  raw: field,
  format: headerName
} as ExportTableFiltersColumn));

export const extractGridColDef = <T extends GridValidRowModel>(customConfigs: { [key in keyof T]?: CustomGridColDef<T>}): GridColDef[] => {
  return Object.entries(customConfigs).map(([key, config]: [string, unknown]) => {
    const colDef: GridColDef = {...config as GridColDef},
          { formatType, widget, headerResourceName, cellConditionalFormat } = config as CustomGridColDef<T>;

    switch(formatType) {
      case ValueType.DATE:
        // eslint-disable-next-line i18next/no-literal-string
        colDef.type = 'date';
        colDef.valueGetter = (value: unknown) => new Date(value as string);
        break;
      case ValueType.DATE_TIME:
        // eslint-disable-next-line i18next/no-literal-string
        colDef.type = 'dateTime';
        colDef.valueGetter = (value: unknown) => new Date(value as string);
        break;
      case ValueType.NUMBER:
      case ValueType.CURRENCY:
      case ValueType.PERCENTAGE:
        // eslint-disable-next-line i18next/no-literal-string
        colDef.type = 'number';
        colDef.valueFormatter = (value: number) => {
          return formatValueByType(value * (formatType === ValueType.PERCENTAGE ? 100 : 1), formatType);
        };
        break;
      default:
      case ValueType.STRING:
        // eslint-disable-next-line i18next/no-literal-string
        colDef.type = 'string';
        break;
    }

    if(cellConditionalFormat) {
      colDef.cellClassName = ({value}: GridCellParams<T>) => {
        // eslint-disable-next-line i18next/no-literal-string
        const className = `${key}-cell-condition`,
              formatConditions = Array.isArray(cellConditionalFormat)
                            ? cellConditionalFormat
                            : [cellConditionalFormat];
                            
        return formatConditions.reduce((defClassName: string, {condition}: GridCellFormat, index: number) => {
          const newClass = condition(value) ? `${className}-${index}` : '';
          return !isEmptyOrWhiteSpace(defClassName) ? defClassName : newClass;
        }, '');
      }
    }

    switch(widget) {
      case GridCellWidgetType.PROGRESS:
        colDef.renderCell = ({value, field, row}: GridRenderCellParams) => {
          // eslint-disable-next-line i18next/no-literal-string
          const maxFieldName = (`max${formatTitle(field)}`),
                percentage = (value * 100)/row[maxFieldName];

          return (
            /* eslint-disable i18next/no-literal-string */
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
              <Box flexGrow={1}>
                <span>{value}</span>
              </Box>
              <Box width={'5rem'}>
                <LinearProgress variant={LINEAR_PROGRESS_DETERMINATE} value={percentage}/>
              </Box>
            </Box>
            /* eslint-enable i18next/no-literal-string */
          );
        };
        break;
    }

    colDef.field ??= key;
    colDef.headerName ??= i18n.t(`TableHeader:${formatTitle(headerResourceName ?? key)}`);

    return colDef;
  });
}
